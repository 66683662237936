export default{
    title: '电子合同后台',
    elseUrl:'http://guanwang.puhuijishu.xyz',
    path:"wss://cscontract.hulianzaowu.top:9501",//webscoket
    appletsBaseURL:'https://wxapplets.puhuijishu.top/api',
    // apiUrl:'http://192.168.0.201:84',//本地
    // apiUrl:'https://cscontract.hulianzaowu.top', //测试服
    // apiUrl:'http://susuyunqian.hulianzaowu.com/', //线上
    // apiUrl:'http://hetong.com',
    // apiUrl:'https://cs1contract.puhuijishu.top', //独立域名测试服
    apiUrl : `${location.origin}`,
}
