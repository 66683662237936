import Vue from "vue";
import index from "./modules/index"
import sign from "./modules/sign"
import setting from "./modules/setting"
import management from "./modules/management"
import application from "./modules/application"
import mainLayout from "@/components/mainLayout"
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  routes:[
    {
      name: '登录',
      path: '/',
      component: ()=>import("@/pages/login/index.vue")
    },
    {
      name: '登录跳转',
      path: '/jump',
      component: ()=>import("@/pages/login/jump.vue")
    },
    {
      name: '登录',
      path: '/admin/login/login',
      component: ()=>import("@/pages/login/login.vue")
    },
    {
      name: '登录',
      path: '/admin/login/login2',
      component: ()=>import("@/pages/login/login2.vue")
    },
    {
      path: "/index",
      meta: {title: '速速云签'},
      component: mainLayout,
      children: [
        ...index,
        ...sign,
        ...setting,
        // setting,
        management,
        ...application
      ]
    }
  ]
})
router.beforeEach((to, from, next) => {
    let companyInfo = JSON.parse(localStorage.getItem(document.domain+'_companyInfo'))
    if (companyInfo != undefined) {
        window.document.title = companyInfo.site_title
    } else {
        // window.document.title = "【速速云签】电子合同、电子签名、电子签章、印章管理、数据存证paap系统服务平台";  
    }
    
    next();
})
export default router;
