<template>
  <div v-show="isLoading" class="page-loading">
     <!-- <img src="@/assets/setimg/20220720113958.gif" alt=""> -->
     <Spin size="large"></Spin>
  </div>
</template>

<script>
export default {
    name: 'PageLoading',
    computed: {
        isLoading() {
            return this.$store.state.isGlobalSpin;
        }
    },
};
</script>

<style lang="less">
.fontad {
    font-size:  50px;
}
.page-loading {
    // position: absolute;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 99999;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.page-loading img{
    width: 50px;
    height: 50px;
}
</style>
