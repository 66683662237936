/**作品路由 */
export default [
    {
        path:'/setting/index',
        name:'settingIndex',
        meta: { title: '系统设置' },
        component: ()=>import('@/pages/setting/index.vue')
    }
]

// /**系统设置 */
// export default {
//     name: 'setting',
//     path: 'setting',
//     meta: {
//         title: '系统设置'
//     },
//     component: ()=>import('@/pages/index.vue'),
//     children: [       
//         {
//             name: 'setting-index',
//             path: '/setting/index',
//             meta: { title: '系统设置' },
//             component: ()=>import('@/pages/setting/index.vue')
//         },
//         {
//             name: 'basic-aplication',
//             path: '/setting/basic',
//             meta: { title: '基本应用' },
//             component: ()=>import('@/pages/setting/basic.vue')
//         },
//         {
//             name: 'application-center',
//             path: '/setting/center',
//             meta: { title: '应用中心' },
//             component: ()=>import('@/pages/setting/center.vue')
//         },
//     ]
// }

